<script lang="ts" setup>
import { reactive, ref } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import LoginUserIcon from '@/assets/images/icons/loginUser'
import LoginPassIcon from '@/assets/images/icons/loginPass'
import ShowPassIcon from '@/assets/images/icons/showPass'

const config = useRuntimeConfig()
import { useTripStore } from '@/store/trip'

const { t } = useI18n()
const tripStore = useTripStore()
const route = useRoute()
const router = useRouter()

const passwordFieldType = ref('password')
const errorsServ = ref()
const loadingSubmit = ref(false)

definePageMeta({
  layout: 'login-layout',
  auth: false,
  // middleware: 'login-access'
})

const switchVisibility = () => {
  passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
}
const loginFormRef = ref<FormInstance>()
const { data, status, signIn, signOut } = useAuth()

const goToRegister = () => {
  router.push({ path: '/auth/register' })
}

const validateEmail = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error(t('forms.wprowadz_email')))
  } else {
    callback()
  }
}
const validatePass = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error(t('forms.wprowadz_hasło')))
  } else {
    callback()
  }
}

const loginForm = reactive({
  email: '',
  password: ''
})

const rules = reactive<FormRules>({
  email: [
    {
      validator: validateEmail,
      trigger: 'blur'
    },
    {
      type: 'email',
      message: t('forms.wprowadz_poprawny_adres_e-mail'),
      trigger: 'blur'
    }
  ],
  password: [{ validator: validatePass, trigger: 'blur' }]
})

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) { return }
  formEl.validate((valid) => {
    if (valid) {
      mySignInHandler({ username: loginForm.email, password: loginForm.password })
    } else {
      return false
    }
  })
}

const mySignInHandler = async ({ username, password }: { username: string, password: string }) => {
  loadingSubmit.value = true
  errorsServ.value = ''
  const { error, url } = await signIn('credentials', { username, password, redirect: false })
  if (error) {

    const isErrorMessage = error.toLowerCase().includes('error:')
    // errorsServ.value = t('forms.nie_znaleziono_uzytkownika_z_przeslanymi_danymi')
    if (isErrorMessage) {
      const newText = error.replace(/Error:/i, '')
      errorsServ.value = newText
    } else {
      errorsServ.value = error
    }
    loadingSubmit.value = false
  } else {
    if (tripStore.buyTripIdEnter && route.href.includes('trip/')) {
      router.push({ path: `/trip/${tripStore.buyTripIdEnter}` })
      return
    }
    router.push({ path: '/' })
    loadingSubmit.value = false
  }
}

</script>
<template>
  <div class="container">
    <el-row>
      <el-col :md="12">
        <div class="" />
      </el-col>
      <el-col :md="12" class="position-box">
        <div class="login-box">
          <div class="login-box__wrap">
            <div class="login-box__header">
              <h3>{{ $t('all.witaj') }}</h3>
              <p>{{ $t('all.zaloguj_sie_aby_kontynuowac') }}</p>
            </div>
            <el-alert
              v-if="route.query.message"
              type="success"
              :description="route.query.message"
              show-icon
              effect="dark"
            />
            <el-form
              ref="loginFormRef"
              :model="loginForm"
              :rules="rules"
              label-width="auto"
              label-position="top"
              class="login-form"
            >
              <el-form-item prop="email">
                <el-input v-model="loginForm.email" :placeholder="$t('forms.E-mail')">
                  <template #prefix>
                    <LoginUserIcon />
                  </template>
                </el-input>
              </el-form-item>

              <el-form-item prop="password">
                <el-input
                  v-model="loginForm.password"
                  :type="passwordFieldType"
                  :placeholder="$t('forms.haslo')"
                  autocomplete="off"
                >
                  <template #prefix>
                    <LoginPassIcon />
                  </template>
                  <template #suffix>
                    <ShowPassIcon class="switchPass" @click="switchVisibility" />
                  </template>
                </el-input>
              </el-form-item>

              <el-alert v-show="errorsServ" :title="errorsServ" type="error" effect="dark" @close="errorsServ = ''" />

              <div class="login-form__control">
                <el-button :loading="loadingSubmit" @click="submitForm(loginFormRef)">
                  {{ $t('all.zaloguj_sie') }}
                </el-button>
                <div class="right-control">
                  <p>{{ $t('all.nie_pamietasz_hasla?') }}</p>
                  <NuxtLink to="/auth/forgot-password" class="link t-decoration-none t-decoration-unline_nover">
                    {{ $t('all.przypomnij') }}
                  </NuxtLink>
                </div>
              </div>
            </el-form>
            <div class="create-control">
              <h3>{{ $t('all.nie_masz_jeszcze_konta?') }}</h3>
              <p>{{ $t('all.zaloz_konto_aby_kontynuowac') }}</p>
              <el-button @click.prevent="goToRegister">
                {{ $t('all.zaloz_konto') }}
              </el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
